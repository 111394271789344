import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/website/node_modules/smooth-doc/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "loadableserver"
    }}><a parentName="h1" {...{
        "href": "#loadableserver",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`@loadable/server`}</h1>
    <h2 {...{
      "id": "chunkextractor"
    }}><a parentName="h2" {...{
        "href": "#chunkextractor",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`ChunkExtractor`}</h2>
    <p>{`Used to collect chunks server-side and get them as script tags or script elements.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Arguments`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`options`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An object options.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`options.statsFile`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Stats file path generated using `}<inlineCode parentName="td">{`@loadable/webpack-plugin`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`options.stats`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Stats generated using `}<inlineCode parentName="td">{`@loadable/webpack-plugin`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`options.entrypoints`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Webpack entrypoints to load (default to `}<inlineCode parentName="td">{`["main"]`}</inlineCode>{`).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`options.outputPath`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional output path (for functions using the file system, like `}<inlineCode parentName="td">{`requireEntrypoint`}</inlineCode>{`).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`options.namespace`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Namespace of your application (use only if you have several React apps on the same page).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`options.inputFileSystem`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`File system used to read files (default to `}<inlineCode parentName="td">{`fs`}</inlineCode>{`).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`options.publicPath`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The public path used at runtime.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`You must specify either `}<inlineCode parentName="p">{`statsFile`}</inlineCode>{` or `}<inlineCode parentName="p">{`stats`}</inlineCode>{` to be able to use `}<inlineCode parentName="p">{`ChunkExtractor`}</inlineCode>{`.`}</p>
    <p>{`Using `}<inlineCode parentName="p">{`statsFile`}</inlineCode>{` will automatically reload stats for you if they change.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { ChunkExtractor } from '@loadable/server'

const statsFile = path.resolve('../dist/loadable-stats.json')
const chunkExtractor = new ChunkExtractor({ statsFile })
`}</code></pre>
    <h2 {...{
      "id": "chunkextractorcollectchunks"
    }}><a parentName="h2" {...{
        "href": "#chunkextractorcollectchunks",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`chunkExtractor.collectChunks`}</h2>
    <p>{`Wrap your application in a `}<inlineCode parentName="p">{`ChunkExtractorManager`}</inlineCode>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Arguments`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`element`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`JSX element that will be wrapped in `}<inlineCode parentName="td">{`ChunkExtractorManager`}</inlineCode>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const app = chunkExtractor.collectChunks(<YourApp />)
`}</code></pre>
    <h2 {...{
      "id": "chunkextractorrequireentrypoint"
    }}><a parentName="h2" {...{
        "href": "#chunkextractorrequireentrypoint",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`chunkExtractor.requireEntrypoint`}</h2>
    <p>{`Require the entrypoint of your application as a commonjs module.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Arguments`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`name`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional name the entrypoint, default to the first one (`}<inlineCode parentName="td">{`main`}</inlineCode>{`).`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const { default: App } = chunkExtractor.requireEntrypoint()
const app = <App />
`}</code></pre>
    <h2 {...{
      "id": "chunkextractorgetscripttags"
    }}><a parentName="h2" {...{
        "href": "#chunkextractorgetscripttags",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`chunkExtractor.getScriptTags`}</h2>
    <p>{`Get scripts as a string of `}<inlineCode parentName="p">{`<script>`}</inlineCode>{` tags.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Arguments`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`attributes`}</inlineCode>{` or `}<inlineCode parentName="td">{`attrFn`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional attributes added to script tags, or a function that receives a `}<inlineCode parentName="td">{`chunk`}</inlineCode>{` and returns the attributes.`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const body = \`<body><div id="root">\${html}</div>\${chunkExtractor.getScriptTags()}</body>\`
`}</code></pre>
    <h2 {...{
      "id": "chunkextractorgetscriptelements"
    }}><a parentName="h2" {...{
        "href": "#chunkextractorgetscriptelements",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`chunkExtractor.getScriptElements`}</h2>
    <p>{`Get scripts as an array of React `}<inlineCode parentName="p">{`<script>`}</inlineCode>{` elements.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Arguments`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`attributes`}</inlineCode>{` or `}<inlineCode parentName="td">{`attrFn`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional attributes added to script elements, or a function that receives a `}<inlineCode parentName="td">{`chunk`}</inlineCode>{` and returns the attributes.`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const body = renderToString(
  <body>
    <div id="root" dangerouslySetInnerHtml={{ __html: html }} />
    {chunkExtractor.getScriptElements()}
  </body>,
)
`}</code></pre>
    <h2 {...{
      "id": "chunkextractorgetlinktags"
    }}><a parentName="h2" {...{
        "href": "#chunkextractorgetlinktags",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`chunkExtractor.getLinkTags`}</h2>
    <p>{`Get "prefetch" and "preload" links as a string of `}<inlineCode parentName="p">{`<link>`}</inlineCode>{` tags.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Arguments`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`attributes`}</inlineCode>{` or `}<inlineCode parentName="td">{`attrFn`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional attributes added to link tags, or a function that receives a `}<inlineCode parentName="td">{`chunk`}</inlineCode>{` and returns the attributes.`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const head = \`<head>\${chunkExtractor.getLinkTags()}</head>\`
`}</code></pre>
    <h2 {...{
      "id": "chunkextractorgetlinkelements"
    }}><a parentName="h2" {...{
        "href": "#chunkextractorgetlinkelements",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`chunkExtractor.getLinkElements`}</h2>
    <p>{`Get "prefetch" and "preload" links as an array of React `}<inlineCode parentName="p">{`<link>`}</inlineCode>{` elements.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Arguments`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`attributes`}</inlineCode>{` or `}<inlineCode parentName="td">{`attrFn`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional attributes added to link elements, or a function that receives a `}<inlineCode parentName="td">{`chunk`}</inlineCode>{` and returns the attributes.`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const head = renderToString(<head>{chunkExtractor.getLinkElements()}</head>)
`}</code></pre>
    <h2 {...{
      "id": "chunkextractorgetstyletags"
    }}><a parentName="h2" {...{
        "href": "#chunkextractorgetstyletags",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`chunkExtractor.getStyleTags`}</h2>
    <p>{`Get style links as a string of `}<inlineCode parentName="p">{`<link>`}</inlineCode>{` tags.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Arguments`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`attributes`}</inlineCode>{` or `}<inlineCode parentName="td">{`attrFn`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional attributes added to style tags, or a function that receives a `}<inlineCode parentName="td">{`chunk`}</inlineCode>{` and returns the attributes.`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const head = \`<head>\${chunkExtractor.getStyleTags()}</head>\`
`}</code></pre>
    <h2 {...{
      "id": "chunkextractorgetstyleelements"
    }}><a parentName="h2" {...{
        "href": "#chunkextractorgetstyleelements",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`chunkExtractor.getStyleElements`}</h2>
    <p>{`Get style links as an array of React `}<inlineCode parentName="p">{`<link>`}</inlineCode>{` elements.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const head = renderToString(<head>{chunkExtractor.getStyleElements()}</head>)
`}</code></pre>
    <h2 {...{
      "id": "chunkextractorgetinlinestyletags"
    }}><a parentName="h2" {...{
        "href": "#chunkextractorgetinlinestyletags",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`chunkExtractor.getInlineStyleTags`}</h2>
    <p>{`Get inline style links as a string of `}<inlineCode parentName="p">{`<link>`}</inlineCode>{` tags (returns a promise).`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Arguments`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`attributes`}</inlineCode>{` or `}<inlineCode parentName="td">{`attrFn`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional attributes added to style tags, or a function that receives a `}<inlineCode parentName="td">{`chunk`}</inlineCode>{` and returns the attributes.`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`chunkExtractor.getInlineStyleTags()
.then((styleTags) => {
  const head = \`<head>\${styleTags}</head>\`
}
`}</code></pre>
    <h2 {...{
      "id": "chunkextractorgetinlinestyleelements"
    }}><a parentName="h2" {...{
        "href": "#chunkextractorgetinlinestyleelements",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`chunkExtractor.getInlineStyleElements`}</h2>
    <p>{`Get inline style links as an array of React `}<inlineCode parentName="p">{`<link>`}</inlineCode>{` elements (returns a promise).`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Arguments`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`attributes`}</inlineCode>{` or `}<inlineCode parentName="td">{`attrFn`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional attributes added to style elements, or a function that receives a `}<inlineCode parentName="td">{`chunk`}</inlineCode>{` and returns the attributes.`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`chunkExtractor.getInlineStyleElements()
.then((styleElements) => {
  const head = renderToString(<head>{styleElements}</head>)
}
`}</code></pre>
    <h2 {...{
      "id": "chunkextractorgetcssstring"
    }}><a parentName="h2" {...{
        "href": "#chunkextractorgetcssstring",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`chunkExtractor.getCssString`}</h2>
    <p>{`Get css as a raw string for using directly within app (e.g. in custom AMP style tag)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`chunkExtractor.getCssString()
.then((cssString) => {
  const head = renderToString(
    <head>
      <style
        dangerouslySetInnerHTML={{ __html: cssString }}
      />
    </head>
  )
}
`}</code></pre>
    <h2 {...{
      "id": "chunkextractormanager"
    }}><a parentName="h2" {...{
        "href": "#chunkextractormanager",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`ChunkExtractorManager`}</h2>
    <p>{`Used to inject a `}<inlineCode parentName="p">{`ChunkExtractor`}</inlineCode>{` in the context of your application.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { ChunkExtractor, ChunkExtractorManager } from '@loadable/server'

const extractor = new ChunkExtractor()

const app = (
  <ChunkExtractorManager extractor={extractor}>
    <YourApp />
  </ChunkExtractorManager>
)
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      